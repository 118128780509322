import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private translocoService: TranslocoService) {
    super();

    this.itemsPerPageLabel = translocoService.translate('common.results_per_page');
    this.nextPageLabel = translocoService.translate('common.next_page');
    this.previousPageLabel = translocoService.translate('common.previous_page');
    this.lastPageLabel = translocoService.translate('common.last_page');
    this.firstPageLabel = translocoService.translate('common.first_page');
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const label = this.translocoService.translate('common.of');
    if (length === 0 || pageSize === 0) {
      return `0 ${label} ${length}`;
    }
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${label} ${length}`;
  };
}
