import { provideHttpClient } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
import { authConfig } from '@shared/feature/auth';
import { hasRoleWithinSurveyAdminPolicy, RoleEN } from '@shared/util/code';
import { infrastructureConfig } from '@shared/util/infrastructure-config';
import { materialConfig } from '@shared/util/material';
import { translationsConfig, TranslocoHttpLoader } from '@shared/util/translations';
import { userConfig } from '@shared/util/user';
import { AppComponent } from './app/app.component';
import { routes } from './app/routes';
import { environment } from './environments/environment';

const activeLanguage = 'en-GB';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule),
    { provide: LOCALE_ID, useValue: activeLanguage },
    authConfig,
    userConfig(hasRoleWithinSurveyAdminPolicy),
    provideHttpClient(),
    provideLoadingBarRouter(),
    materialConfig(),
    translationsConfig(TranslocoHttpLoader.getTransLocoConfig(environment.production, activeLanguage), RoleEN),
    infrastructureConfig(environment.apiUrl),
    provideAnimations(),
    provideRouter(routes),
  ],
}).catch((err) => console.error(err));
