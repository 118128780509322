import { APP_BASE_HREF, DOCUMENT, LocationStrategy, PlatformLocation } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { EnvironmentProviders, ErrorHandler, Injector, Provider, inject, provideAppInitializer } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { InitialThemingService } from '@shared/feature/theme-service';
import { DialogAlertComponent } from '@shared/ui/dialog';
import { switchMap } from 'rxjs';
import { preLoadLanguage } from '../translations';
import {
  API_ADMIN_BASE_URL,
  API_BASE_URL,
  API_EXPORT_BASE_URL,
  API_NODEJS_BASE_URL,
  API_PORTAL_BASE_URL,
  API_REGISTRATION_BASE_URL,
  API_REPORT_BASE_URL,
  API_RESPONSE_BASE_URL,
  API_SINGLE_REPORT_BASE_URL,
  PROJECT_GUID,
  TOOLS_BASE_URLS,
  getToolBaseUrls,
} from './config';
import { AppSettingsService } from './core/app-settings.service';
import { GlobalErrorHandlerService } from './core/global-error-handler.service';
import { guidInterceptor } from './core/guid.interceptor';
import { loadingInterceptor } from './core/loading.interceptor';

// eslint-disable-next-line max-lines-per-function
export function infrastructureConfig(apiUrl: string): Provider | EnvironmentProviders {
  return [
    provideHttpClient(withInterceptors([guidInterceptor, loadingInterceptor])),
    DialogAlertComponent,
    {
      provide: ErrorHandler,
      useFactory: (injector: Injector, document: Document, router: Router, locationStrategy: LocationStrategy) =>
        new GlobalErrorHandlerService(document, injector, router, locationStrategy),
      deps: [Injector, DOCUMENT, Router, LocationStrategy],
    },
    {
      provide: TOOLS_BASE_URLS,
      useFactory: (document: Document) => getToolBaseUrls(document.location),
      deps: [DOCUMENT],
    },
    {
      provide: API_EXPORT_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.exportApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_REPORT_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.reportGroupsApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_SINGLE_REPORT_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.reportApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_PORTAL_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.portalApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_RESPONSE_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.responseApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_ADMIN_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.adminApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: PROJECT_GUID,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.projectGuid,
      deps: [AppSettingsService],
    },
    {
      provide: API_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.apiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_REGISTRATION_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.registrationApiUrl,
      deps: [AppSettingsService],
    },
    {
      provide: API_NODEJS_BASE_URL,
      useFactory: (appSettingsService: AppSettingsService) => appSettingsService.nodeJs,
      deps: [AppSettingsService],
    },
    provideAppInitializer(() => {
      const initializerFn = (
        (settings: AppSettingsService, initialThemingService: InitialThemingService, translocoService: TranslocoService) => () =>
          settings.init(apiUrl).pipe(
            switchMap((x) => initialThemingService.initialize(x.portalApiUrl, settings.projectGuid)),
            switchMap(() => preLoadLanguage(translocoService)()),
          )
      )(inject(AppSettingsService), inject(InitialThemingService), inject(TranslocoService));
      return initializerFn();
    }),
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => (s.getBaseHrefFromDOM().endsWith('/') ? s.getBaseHrefFromDOM().slice(0, -1) : s.getBaseHrefFromDOM()),
      deps: [PlatformLocation],
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        restoreFocus: true,
        autoFocus: true,
        minWidth: 450,
        hasBackdrop: true,
        disableClose: true,
      },
    },
    { provide: MatBottomSheet }, // needed for bottomSheet reference in loading-indicator.service
  ];
}
